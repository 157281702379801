<template>
  <div>
    <!--		header base-->
    <app-header
      :config="config"
      v-if="config.nav_active === 1 && headerTemplate === 'base'"
    ></app-header>
    <!--		header soauto vgrp-->
    <app-header-soauto-vgrp
      :config="config"
      v-if="config.nav_active === 1 && headerTemplate === 'soauto_vgrp'"
    ></app-header-soauto-vgrp>

    <!--		header santogal-->
    <app-header-santogal-renault
      :config="config"
      v-if="config.nav_active === 1 && headerTemplate === 'renault_santogal'"
    ></app-header-santogal-renault>

    <app-header-santogal-dacia
      :config="config"
      v-if="config.nav_active === 1 && headerTemplate === 'dacia_santogal'"
    ></app-header-santogal-dacia>

    <!--        content top-->
    <div class="container-fluid p-0">
      <!--			catalog v1 module-->
      <catalog-container-v1-refresh
        v-if="config.catalog !== null"
        :config="config"
        :form-data="formData"
        :setup="setup"
        :privacy-text="setPrivacyText()"
        :campaign-contract="campaignContract"
      ></catalog-container-v1-refresh>
    </div>

    <!--        cta -->
    <div
      class="container-fluid cta-bg pt-lg-3"
      id="cta2"
      v-if="jsonConfig.cta !== undefined"
    >
      <div class="row">
        <div
          class="col-12 mt-3 mt-md-3 cta mb-3 mb-md-3 text-center d-none d-lg-block"
        >
          <p>
            <span class="cta_text_1" v-html="jsonConfig.cta.text1"></span>
            <span class="cta_text_2" v-html="jsonConfig.cta.text2"></span>
          </p>
        </div>
      </div>
    </div>

    <!--        flipclock-->
    <div
      class="container-fluid pt-2 pb-2"
      id="countdown-clock"
      v-if="jsonConfig.countdown !== undefined"
    >
      <div class="row">
        <div class="col-12">
          <div class="d-flex justify-content-center">
            <flip-countdown
              :deadline="jsonConfig.countdown.deadline"
              :labels="labels"
            ></flip-countdown>
          </div>
        </div>
      </div>
    </div>

    <!--        after form -->
    <div
      class="container-fluid container_after_form"
      v-if="jsonConfig.container_after_form !== undefined"
      :class="{
        'd-none d-md-block': jsonConfig.container_after_form.active_top,
      }"
    >
      <div class="row">
        <div class="col-12">
          <div class="container">
            <div class="row">
              <div
                class="col-12 col-md-4 pb-3 pb-md-0"
                :class="{
                  'offset-md-4':
                    jsonConfig.container_after_form.bullets.length === 1,
                  'offset-md-2':
                    jsonConfig.container_after_form.bullets.length === 2 &&
                    i !== 1,
                }"
                v-for="(item, i) of jsonConfig.container_after_form.bullets"
                :key="i"
                :index="i"
              >
                <div class="row">
                  <div class="col-3">
                    <img v-lazy="config.bullets[i]" alt="" width="60px" />
                  </div>
                  <div class="col-9">
                    <h4>{{ item.title }}</h4>
                    <p>{{ item.text }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--        navigate-out -->
    <div
      class="container-fluid container-navigate-out"
      id="navigate-out-top"
      v-if="jsonConfig.navigate_out_zone !== undefined"
    >
      <div class="container">
        <div class="row">
          <div
            class="col-12 d-md-flex justify-content-md-center text-center align-content-center"
          >
            <div
              class="pb-1 pt-1"
              v-html="jsonConfig.navigate_out_zone.text"
            ></div>
            <div class="pb-1 pt-2 pt-md-1 p-md-0 pl-0 pl-md-4">
              <a
                v-bind:href="jsonConfig.navigate_out_zone.href"
                target="_blank"
                rel="noopener"
                v-html="jsonConfig.navigate_out_zone.button_text"
              ></a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--       tabs static-content-->
    <div
      class="container-fluid container-tabbed-static-content"
      v-if="jsonConfig.tabbed_static_content !== undefined"
    >
      <div class="container">
        <div class="row">
          <div class="col-12">
            <tabbed-static-content
              :config="jsonConfig.tabbed_static_content"
            ></tabbed-static-content>
          </div>
        </div>
      </div>
    </div>

    <!--        slideshow-->
    <div
      class="container-fluid container-slideshow"
      v-if="jsonConfig.slideshow !== undefined"
    >
      <div class="row">
        <slideshow
          :json-config="jsonConfig"
          :images="config.slideshow"
        ></slideshow>
      </div>
      <div class="d-flex justify-content-center">
        <a
          class="btn btn-primary slideshow-btn"
          href="#form"
          v-html="jsonConfig.slideshow.button"
        ></a>
      </div>
    </div>

    <!--        slideshow v2-->
    <div
      class="container-fluid container-slideshow"
      v-if="
        jsonConfig.slideshowv2 !== undefined &&
        jsonConfig.slideshowv2.length > 0
      "
    >
      <slideshow-v2 :json-config="jsonConfig"></slideshow-v2>
    </div>

    <!--        galleries-->
    <div class="container-fluid container-gallery">
      <div class="container" v-if="jsonConfig.gallery !== undefined">
        <div class="row mt-5" style="margin-left: 0; margin-right: 0">
          <div class="col-12">
            <gallery
              :gallery-images="config.gallery"
              :size="jsonConfig.gallery.size"
            ></gallery>
            <div class="d-flex justify-content-center">
              <a
                class="btn btn-primary slideshow-btn"
                href="#form"
                v-html="jsonConfig.gallery.button"
              ></a>
            </div>
          </div>
        </div>
      </div>

      <div class="container" v-if="jsonConfig.galleryv2 !== undefined">
        <div class="row mt-5" style="margin-left: 0; margin-right: 0">
          <div class="col-12">
            <h2
              class="text-center pb-3"
              v-html="
                jsonConfig.galleryv2.header !== undefined
                  ? jsonConfig.galleryv2.header
                  : 'Viaturas em destaque!'
              "
            ></h2>
            <gallery2
              :gallery-images="jsonConfig.galleryv2.images"
              :size="jsonConfig.galleryv2.size"
            ></gallery2>
            <div class="d-flex justify-content-center">
              <a
                class="btn btn-primary slideshow-btn"
                href="#form"
                v-html="jsonConfig.galleryv2.button"
                v-if="jsonConfig.galleryv2.button_active"
              ></a>
            </div>
          </div>
        </div>
      </div>

      <div
        class="container"
        v-if="
          jsonConfig.galleryv3 !== undefined && jsonConfig.galleryv3.length > 0
        "
      >
        <div class="row mt-5" style="margin-left: 0; margin-right: 0">
          <div class="col-12 mb-3" v-for="gallery in jsonConfig.galleryv3">
            <h2
              class="text-center pb-3"
              v-html="
                gallery.header !== undefined
                  ? gallery.header
                  : 'Viaturas em destaque!'
              "
            ></h2>
            <gallery2
              :gallery-images="gallery.images"
              :size="gallery.size"
            ></gallery2>
            <div class="d-flex justify-content-center">
              <a
                class="btn btn-primary slideshow-btn"
                href="#form"
                v-html="gallery.button"
                v-if="gallery.button_active"
              ></a>
            </div>
          </div>
        </div>
      </div>

      <!--        youtube start-->
      <div class="container" v-if="jsonConfig.youtube !== undefined">
        <app-video :json-config="jsonConfig"></app-video>
      </div>
    </div>

    <!--        feed -->
    <div
      v-if="
        config.catalog !== null &&
        jsonConfig.catalog_feed !== undefined &&
        catalogReady
      "
      class="text-center mt-3"
    >
      <div class="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>

    <div
      class="container-fluid container-catalog-feed"
      v-if="
        config.catalog !== null &&
        jsonConfig.catalog_feed !== undefined &&
        catalogReady
      "
    >
      <div class="container">
        <div class="row">
          <div class="col-12">
            <catalog-feed
              :catalog-data="catalogData"
              :module-config="jsonConfig.catalog_feed"
              :config="config"
              :fuel-type="fuelType"
              :makes="makes"
              :models="models"
              :addresses="requestAddresses"
              :prices="prices"
            ></catalog-feed>
          </div>
        </div>
      </div>
    </div>

    <!--        navigate-out -->
    <div
      class="container-fluid container-navigate-out"
      id="navigate-out-bottom"
      style="display: none"
      v-if="jsonConfig.navigate_out_zone !== undefined"
    >
      <div class="container">
        <div class="row">
          <div
            class="col-12 d-md-flex justify-content-md-center text-center align-content-center"
          >
            <div
              class="pb-1 pt-1"
              v-html="jsonConfig.navigate_out_zone.text"
            ></div>
            <div class="pb-1 pt-2 pt-md-1 p-md-0 pl-0 pl-md-4">
              <a
                v-bind:href="jsonConfig.navigate_out_zone.href"
                target="_blank"
                rel="noopener"
                v-html="jsonConfig.navigate_out_zone.button_text"
              ></a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--        address -->
    <div
      class="container-fluid container-bottom mt-3"
      style="margin-bottom: 5rem"
    >
      <address-v2
        v-if="
          addressStyle === 'v2' && footerLocations.length > 0 && loadLocations
        "
        :config="footerLocations"
      ></address-v2>

      <div class="container">
        <div class="row">
          <div
            class="col-12 mt-3 mt-md-3 disclaimer mb-3 mb-md-3"
            v-if="setup.disclaimer_zone === 'bottom'"
          >
            <p id="privacy2" v-html="setPrivacyText()"></p>
            <p id="contract2" v-html="campaignContract"></p>
            <p id="disclaimer2" v-html="setup.campaign_disclaimer"></p>
          </div>
        </div>
      </div>

      <address-v1
        v-if="
          addressStyle === 'v1' && footerLocations.length > 0 && loadLocations
        "
        :config="footerLocations"
        :setup="setup"
      ></address-v1>
    </div>

    <!--       whatsapp -->
    <whatsapp
      v-if="whatsappActive"
      id="whatsapp-float"
      :config="config"
    ></whatsapp>

    <!--sticky button-->
    <div
      class="sticky-button d-none d-md-none"
      v-if="config.sticky_button === 1"
    >
      <div
        class="d-flex justify-content-start"
        :class="{
          'justify-content-around':
            (config.sticky_button_text !== null &&
              config.sticky_button_call_number !== null) ||
            (config.sticky_button_text === null &&
              config.sticky_button_call_number !== null &&
              !whatsappActive) ||
            (config.sticky_button_call_number === null &&
              config.sticky_button_text !== null &&
              !whatsappActive),
        }"
      >
        <a
          class="btn btn-primary mr-1 ml-1"
          href="#form"
          v-if="config.sticky_button_text !== null"
          v-html="config.sticky_button_text"
        ></a>
        <a
          class="btn btn-primary mr-1 ml-1"
          :href="'tel:' + config.sticky_button_call_number"
          @click="triggerCall()"
          v-if="config.sticky_button_call_number !== null"
          >Ligar</a
        >
      </div>
    </div>

    <!--footer -->
    <app-footer
      :config="config"
      :setup="setup"
      :seller-id="sellerId"
      :force-form-marketing-texts="formForceMarketingTexts"
      v-if="footerTemplate === 'base'"
    ></app-footer>
    <!--footer soauto vgrp-->
    <app-footer-soauto-vgrp
      :config="config"
      :setup="setup"
      :seller-id="sellerId"
      v-if="footerTemplate === 'soauto_vgrp'"
    ></app-footer-soauto-vgrp>

    <!-- footer Lubrigaz -->
    <app-footer-lubrigaz
      :config="config"
      :setup="setup"
      :seller-id="sellerId"
      v-if="footerTemplate === 'lubrigaz'"
    ></app-footer-lubrigaz>
    <app-footer-lubrigaz-skoda
      :config="config"
      :setup="setup"
      :seller-id="sellerId"
      v-if="footerTemplate === 'lubrigaz_skoda'"
    ></app-footer-lubrigaz-skoda>
    <app-footer-lubrisport
      :config="config"
      :setup="setup"
      :seller-id="sellerId"
      v-if="footerTemplate === 'lubrisport'"
    ></app-footer-lubrisport>

    <!--footer santogal-->
    <app-footer-santogal-renault
      :config="config"
      :setup="setup"
      :seller-id="sellerId"
      v-if="footerTemplate === 'renault_santogal'"
    ></app-footer-santogal-renault>

    <app-footer-santogal-dacia
      :config="config"
      :setup="setup"
      :seller-id="sellerId"
      v-if="footerTemplate === 'dacia_santogal'"
    ></app-footer-santogal-dacia>

    <debugger :config="config"></debugger>
  </div>
</template>

<script>
import "@/assets/styles/scss/base-template.scss";
import setFontMixin from "@/common/set-font-mixin";
import setStylesMixin from "@/common/set-styles-mixin";
import setWhatsappPosMixin from "@/common/set-whatsapp-pos-mixin";
import Disclaimer from "@/components/shared/Disclaimer";
import WhatsappFloat from "@/components/shared/WhatsappFloat";
import router from "@/router";
import VueGtm from "@gtm-support/vue2-gtm";
import axios from "axios";
import * as moment from "moment";
import Vue from "vue";
import FlipCountdown from "vue2-flip-countdown";
import { mapState } from "vuex";

moment.locale("pt");

const Header = () =>
  import(/* webpackChunkName: "catalogs" */ "@/components/Header");
const HeaderSantogalRenault = () =>
  import(
    /* webpackChunkName: "catalogs" */ "@/components/HeaderSantogalRenault"
  );
const HeaderSantogalDacia = () =>
  import(/* webpackChunkName: "index" */ "@/components/HeaderSantogalDacia");
const HeaderSoautoVGRP = () =>
  import(/* webpackChunkName: "index" */ "@/components/HeaderSoautoVGRP");
const FooterSoautoVGRP = () =>
  import(/* webpackChunkName: "index" */ "@/components/FooterSoauto");
const FooterLubrigaz = () =>
  import(/* webpackChunkName: "index" */ "@/components/FooterLubrigaz");
const FooterLubrigazSkoda = () =>
  import(/* webpackChunkName: "index" */ "@/components/FooterLubrigazSkoda");
const FooterLubrisport = () =>
  import(/* webpackChunkName: "index" */ "@/components/FooterLubrisport");
const FooterSantogalRenault = () =>
  import(
    /* webpackChunkName: "catalogs" */ "@/components/FooterSantogalRenault"
  );
const FooterSantogalDacia = () =>
  import(/* webpackChunkName: "index" */ "@/components/FooterSantogalDacia");
const Footer = () =>
  import(/* webpackChunkName: "catalogs" */ "@/components/Footer");
const Video = () =>
  import(/* webpackChunkName: "catalogs" */ "@/components/shared/Video");
const TabbedStaticContent = () =>
  import(
    /* webpackChunkName: "catalogs" */ "@/components/shared/TabbedStaticContent"
  );

export default {
  name: "Base",
  components: {
    Debugger: () =>
      import(/* webpackChunkName: "catalogs" */ "@/components/Debugger"),
    CatalogContainerV1Refresh: () =>
      import(
        /* webpackChunkName: "catalogs" */ "@/components/shared/catalog/CatalogContainerV1Refresh"
      ),
    "app-header": Header,
    "app-footer": Footer,
    "app-header-soauto-vgrp": HeaderSoautoVGRP,
    "app-header-santogal-renault": HeaderSantogalRenault,
    "app-header-santogal-dacia": HeaderSantogalDacia,
    "app-footer-lubrigaz": FooterLubrigaz,
    "app-footer-lubrigaz-skoda": FooterLubrigazSkoda,
    "app-footer-lubrisport": FooterLubrisport,
    "app-footer-santogal-renault": FooterSantogalRenault,
    "app-footer-santogal-dacia": FooterSantogalDacia,
    "app-footer-soauto-vgrp": FooterSoautoVGRP,
    disclaimer: Disclaimer,
    Gallery: () =>
      import(/* webpackChunkName: "catalogs" */ "@/components/shared/Gallery"),
    Gallery2: () =>
      import(/* webpackChunkName: "catalogs" */ "@/components/shared/Gallery2"),
    Slideshow: () =>
      import(
        /* webpackChunkName: "catalogs" */ "@/components/shared/Slideshow"
      ),
    SlideshowV2: () =>
      import(
        /* webpackChunkName: "catalogs" */ "@/components/shared/SlideshowV2"
      ),
    FlipCountdown,
    whatsapp: WhatsappFloat,
    CatalogFeed: () =>
      import(
        /* webpackChunkName: "catalogs" */ "@/components/shared/CatalogFeed"
      ),
    "tabbed-static-content": TabbedStaticContent,
    AddressV1: () =>
      import(
        /* webpackChunkName: "catalogs" */ "@/components/shared/AddressV1"
      ),
    AddressV2: () =>
      import(
        /* webpackChunkName: "catalogs" */ "@/components/shared/AddressV2"
      ),
    ContactForm: () =>
      import(
        /* webpackChunkName: "catalogs" */ "@/components/shared/ContactForm"
      ),
    "app-video": Video,
    "app-footer-lubrigaz": FooterLubrigaz,
    "app-footer-lubrigaz-skoda": FooterLubrigazSkoda,
    "app-footer-lubrisport": FooterLubrisport,
  },
  mixins: [setStylesMixin, setWhatsappPosMixin, setFontMixin],
  props: {
    response: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      // base settings
      config: Object,
      setup: Object,
      cssData: Object,
      jsonConfig: Object,
      formData: Object,

      gtmId: String,
      cookiesText: String,
      formForceMarketingTexts: Boolean,
      whatsappActive: Boolean,

      headerTemplate: "base",
      footerTemplate: "base",
      addressStyle: "null",

      sellerStands: Object,
      carouselData: 0,
      sellerId: String,
      logo: String,
      hasLogo: Boolean,
      logoAlt: String,
      addresses: String,
      headerConfig: Object,
      logo_left: String,
      logos_right: String,
      catalogReady: false,
      catalogData: Array,
      fuelType: Object,
      makes: Object,
      models: Array,
      requestAddresses: Object,
      prices: Array,
      campaignContract: String,
      isVisible: false,
      listingId: String,
      footerLocations: [],
      loadLocations: false,

      //flip-clock
      labels: {
        days: "dias",
        hours: "horas",
        minutes: "minutos",
        seconds: "segundos",
      },
    };
  },
  computed: mapState(["fatForm", "formPlacement"]),
  watch: {
    fatForm(newValue, oldValue) {
      this.formData.fat_form = newValue;
    },
    carouselData() {
      this.$refs.carousel.slideTo(this.carouselData);
    },
    formPlacement(newValue, oldValue) {
      this.formData.form_placement = newValue;
    },
  },
  updated() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  created() {
    this.setData(this.response);
    this.setInitStyles();
    this.setFavIco(this.config.favicon !== null ? this.config.favicon : "");
    this.setTitle(
      this.config.meta_title !== null ? this.config.meta_title : ""
    );
    this.setFont();
    this.setCountDownFont();
    this.setMetas(this.config);

    this.$eventBus.$on("change-version", (data) => {
      this.form.fields.version = data;
    });

    if (this.config.footer_locations === null) {
      this.$eventBus.$on("set-location", (data) => {
        this.footerLocations = [data];
      });
      this.loadLocations = true;
    } else {
      this.footerLocations = this.config.footer_locations;
      this.loadLocations = true;
    }

    this.$eventBus.$on("set-cookies-gtm", (data) => {
      if (data) {
        this.setGtm();
        let sessionCookie = {
          accepted: true,
          domain: window.location.hostname,
        };
        this.$cookies.set(
          `${this.sellerId}-autoleads-cookie`,
          sessionCookie,
          "7d",
          "",
          ""
        );
      }
    });

    this.setPos();
  },
  methods: {
    triggerCall() {
      if (
        this.$cookies.get(`${this.sellerId}-autoleads-cookie`) === null &&
        !this.setup.force_cookie_consent
      ) {
        this.setGtm();
      }
      let vm = this;
      setTimeout(() => {
        vm.$gtm.trackEvent(
          {
            event: "Phone_call", // Event type [default = 'interaction'] (Optional)
            category: "Phone_call",
            action: "Phone_call",
            label: "Phone_call",
            eventID: vm.$store.state.alid,
            value: 0,
            noninteraction: false, // Optional
          },
          500
        );
        window.alp.trackEvent({ event: "Phone_call" });
      });
    },
    changeVisible(isVisible, entry) {
      if (document.getElementsByClassName("sticky-button").length > 0) {
        if (!isVisible && document.body.clientWidth <= 768) {
          document
            .getElementsByClassName("sticky-button")[0]
            .setAttribute("style", "display:block !important;");
        }
        if (isVisible && document.body.clientWidth <= 768) {
          document
            .getElementsByClassName("sticky-button")[0]
            .setAttribute("style", "display:none !important;");
        }
      }
    },
    visibilityChanged(isVisible, entry) {
      this.isVisible = isVisible;
    },
    setCampaignContractText() {
      if (this.config.campaign_contract !== null) {
        return this.config.campaign_contract;
      }

      return "";
    },
    setData(response) {
      if (process.env.NODE_ENV === "development") {
        console.log(response);
      }
      // Page Data
      this.config = response;
      this.setup = response.setup;
      this.cssData = response.css !== null ? response.css : {};
      this.formData = response.form !== null ? response.form : {};
      this.jsonConfig =
        response.json !== null && response.json.json_config !== null
          ? response.json.json_config
          : {};

      //trackers
      this.gtmId = this.setup.gtm_id !== null ? this.setup.gtm_id : "GTM-XXXXX";
      this.sellerId = this.setup.seller_id;

      //whatsapp
      this.whatsappActive = response.whatsapp_active === 1;
      //form
      this.formForceMarketingTexts =
        this.setup.form_force_marketing_texts === 1;
      //modules
      this.headerTemplate = this.setup.header_template;
      this.footerTemplate = this.setup.footer_template;
      this.addressStyle =
        this.config.address_style === null ? "null" : this.config.address_style;

      // this.hasLogo                       = this.config.body.addressv1.logos.src !== '' && this.config.body.addressv1.logos.src !== undefined;
      // this.logo                          = this.config.body.addressv1.logos.src;
      // this.logoAlt                       = this.config.body.addressv1.logos.alt;
      // this.addresses                     = this.config.body.addressv1.addresses;

      // this.form.fields.version  = this.config.form.extra_fields.version && this.config.form.fields_settings.version.select_values.length < 2 ? this.config.form.fields_settings.version.select_values[0].id : '';
      // this.form.fields.location = this.config.form.extra_fields.location && this.config.form.fields_settings.location.select_values.length < 2 ? this.config.form.fields_settings.location.select_values[0].id : '';

      // catalog feed init
      if (
        this.config.catalog !== null &&
        this.jsonConfig !== null &&
        this.jsonConfig.catalog_feed !== undefined
      ) {
        this.fetchCatalogData(this.config.catalog.id);
      }

      if (
        this.$cookies.get(`${this.sellerId}-autoleads-cookie`) !== null &&
        this.$cookies.get(`${this.sellerId}-autoleads-cookie`).accepted &&
        this.$cookies.get(`${this.sellerId}-autoleads-cookie`).domain ===
          window.location.hostname
      ) {
        setTimeout(() => {
          this.setGtm();
        }, 500);
      }

      this.campaignContract = this.setCampaignContractText();
    },
    fetchCatalogData(id) {
      let vm = this;

      axios
        .get(
          `${process.env.VUE_APP_API_URL}/api/ad-engine/landing-page/feed/${id}`,
          {
            params: {
              fuel_type_id: vm.$route.query.fuel_type_id
                ? vm.$route.query.fuel_type_id
                : "",
              car_make_id: vm.$route.query.car_make_id
                ? vm.$route.query.car_make_id
                : "",
              address_id: vm.$route.query.address_id
                ? vm.$route.query.address_id
                : "",
              model_id: vm.$route.query.model_id
                ? vm.$route.query.model_id
                : "",
              sale_price: vm.$route.query.sale_price
                ? vm.$route.query.sale_price
                : "",
              order: vm.$route.query.order ? vm.$route.query.order : "",
            },
          }
        )
        .then(function (response) {
          vm.catalogData = response.data.results.data;
          vm.fuelType = response.data.fuel_type;
          vm.makes = response.data.makes;
          vm.requestAddresses = response.data.addresses;
          vm.models = response.data.models;
          vm.prices = response.data.prices;
          vm.catalogReady = true;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    setMetas() {
      this.helpers.setMetas(this.config);
    },

    setGtm() {
      let ids;
      if (this.gtmId.indexOf(",") > -1) {
        ids = this.gtmId.split(",");
      } else {
        ids = this.gtmId;
      }
      Vue.use(VueGtm, {
        id: ids, // Your GTM ID
        enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
        debug: true, // Whether or not display console logs debugs (optional)
        vueRouter: router, // Pass the router instance to automatically sync with router (optional)
        ignoredViews: [], // If router, you can exclude some routes name (case insensitive) (optional)
      });
      this.$eventBus.$emit("set-gtm", true);
    },

    setFavIco(icoUrl) {
      document.getElementById("favico").setAttribute("href", icoUrl);
    },

    setTitle(title) {
      document
        .getElementsByTagName("title")[0]
        .appendChild(document.createTextNode(title));
    },
    setCountDownFont() {
      if (this.jsonConfig.countdown !== undefined) {
        const s = document.createElement("link");
        s.rel = "stylesheet";
        s.href = this.jsonConfig.countdown.font;
        document.head.appendChild(s);
      }
    },
    setPrivacyText() {
      return this.helpers.setPrivacyText(this.setup);
    },
  },
};
</script>
